// display flex was causing errors
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active{
    display: block;
}

.carousel{
    .carousel-inner{
        box-shadow: $box-shadow-raised;
        border-radius: $border-radius-small;
    }

    .card {
        max-width: 650px;
        margin: 60px auto;
        display: block;
    }
}

.carousel-item > img {
    width: 100%;
}

.carousel-caption {
    font-family: "Oswald", arial, sans-serif;
    font-size: 30pt;
    text-transform: uppercase;
    position: center;
}
