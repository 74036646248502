img{
    max-width: 100%;
    border-radius: 1px;
}
.img-raised{
    box-shadow: $box-shadow-raised;
}

.slant-image-header {
    width: 100%;
    height: 80vh;
    position: relative;
    display: grid;
    grid-template-rows: max-content 1fr;
    grid-template-columns: 1fr max-content;

    &__bg {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        filter: brightness(60%);
        clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }

    &__title {
        grid-area: 2 / 1 / 2 / 3;
        align-self: center;
        justify-self: center;
        color: white;
        text-transform: uppercase;
        font-size: 4rem;
        text-shadow: 0px 2px 4px rgba(0,0,0,.4);
        margin-top: -5rem;
    }
}


